import React from "react"
import classNames from "classnames"

import LineItemCardRow from "./LineItemCard/LineItemCardRow"

import styles from "./utils/cart.module.scss"

const LineItemCard = ({ open, items, setFieldValue }) => {
  const handleDeleteLineItem = (index) => {
    setFieldValue(items, items.splice(index, 1))
  }

  return (
    <div>
      <ol className={classNames(styles["box"], "has-text-left")}>
        {items?.map((item, index) => (
          <LineItemCardRow
            lineItem={item}
            index={index}
            handleDeleteLineItem={handleDeleteLineItem}
          />
        ))}
      </ol>
    </div>
  )
}

export default LineItemCard
