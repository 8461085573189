import React, { useContext } from "react"

import Layout from "layout"
import Upload from "../../../Upload"
import { FlexmedReimburseCart } from "../../Cart/index"

import guidelines from "./utils/uploadGuidelines.json"
import useFlexmedImages from "./hooks/useFlexmedImages"

import { flexmedReimburseStep } from "../../Reimburse/utils/flexmedReimburseSteps"
import { FlexmedReimburseContext } from "../../FlexmedContext/FlexmedReimburseContext"

const FlexmedReimburseRxUpload = ({ pageContext, location }) => {
  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )
  const data = useFlexmedImages()
  const fileUploadPrescription = data?.prescription?.childImageSharp?.fixed
  const MAX_FILE_SIZE_IN_BYTES = 4000000
  const MAX_FILE_COUNT = 6

  const CURRENT_STEP = 1
  const TOTAL_STEPS = 3

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      steps={flexmedReimburseStep}
      location={location}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      path={pageContext?.module?.path}
      hasMobileStepper={true}
    >
      <Upload
        pageContext={pageContext}
        icon={fileUploadPrescription}
        maxFileCount={MAX_FILE_COUNT}
        maxFileSize={MAX_FILE_SIZE_IN_BYTES}
        guidelines={guidelines}
        dropzoneLabel="Upload Documents"
        cartContainer={
          <FlexmedReimburseCart
            open={{ documentsCard: true }}
            flexmedReimburseState={flexmedReimburseState}
            flexmedReimburseDispatch={flexmedReimburseDispatch}
            pageContext={pageContext}
          />
        }
        documents={flexmedReimburseState.documents}
        moduleState={flexmedReimburseState}
        moduleDispatch={flexmedReimburseDispatch}
        module="reimburse"
      />
    </Layout>
  )
}

export default FlexmedReimburseRxUpload
