import React from "react"

import MedicinesCard from "../../Cart/MedicinesCard"
import DocumentsCard from "../../Cart/DocumentsCard"
import LineItemCard from "../../Cart/LineItemCard"

import { getPathName } from "../../../services/general"

const FlexmedCashlessCart = ({
  open,
  flexmedCashlessState,
  flexmedCashlessDispatch,
  pageContext,
}) => {
  return (
    <div className="mt-2-mobile">
      <DocumentsCard
        open={open?.documentsCard}
        baseRoute={pageContext?.basePath}
        isFullSize={open?.documentsIsFullSize}
        moduleState={flexmedCashlessState}
        moduleDispatch={flexmedCashlessDispatch}
        pageContext={pageContext}
      />
      {!getPathName().includes("upload") && (
        <MedicinesCard
          open={open?.medicinesCard}
          baseRoute={pageContext?.basePath}
          moduleState={flexmedCashlessState}
          moduleDispatch={flexmedCashlessDispatch}
          showPrices
          flow="cashless"
          helper={{
            message:
              "Order total will be deducted from your FlexMed allowance. No cash-out required.",
          }}
          pageContext={pageContext}
        />
      )}
    </div>
  )
}

export const FlexmedReimburseCart = ({
  open,
  flexmedReimburseState,
  flexmedReimburseDispatch,
  pageContext,
}) => {
  return (
    <div className="mt-2-mobile">
      <DocumentsCard
        open={open?.documentsCard}
        baseRoute={pageContext?.basePath}
        isFullSize={open?.documentsIsFullSize}
        moduleState={flexmedReimburseState}
        moduleDispatch={flexmedReimburseDispatch}
        pageContext={pageContext}
        type="Documents"
      />
    </div>
  )
}

export const FlexmedEncodeReceiptCart = ({ open, items, setFieldValue }) => {
  return (
    <div className="mt-2-mobile">
      <LineItemCard
        open={open?.medicinesCard}
        items={items}
        setFieldValue={setFieldValue}
      />
    </div>
  )
}

export default FlexmedCashlessCart
