import React from "react"
import classNames from "classnames"

import { formatPrice } from "../../Epharmacy/services/computations"
import styles from "../utils/cart.module.scss"

const LineItemCardRow = ({ lineItem, handleDeleteLineItem, index }) => {
  return (
    <li>
      <div className="columns mb-0 is-mobile">
        <div className={classNames("column")}>
          {/* Brand + delete button */}
          <div className="is-flex is-justify-content-space-between">
            <span>{lineItem.brand || "Other Medicines"}</span>
            <button
              type="button"
              onClick={() => handleDeleteLineItem(index)}
              className={classNames([
                styles["closeButton"],
                "is-pulled-right ml-1",
              ])}
            >
              <p className="delete" />
            </button>
          </div>
          {/* Molecule + lineItemTotal */}
          <div className="is-flex is-justify-content-space-between">
            <span className="has-text-weight-bold has-text-primary">
              {lineItem.molecule || lineItem.SKU}
            </span>
            <span className="has-text-weight-bold">
              PHP{" "}
              {formatPrice({
                priceString: lineItem.itemPrice.toString(),
              })}
            </span>
          </div>
          {/* Strength + Form */}
          <div className="is-flex is-justify-content-space-between">
            <span>{`${lineItem.strength || ""} ${lineItem.form || ""}`}</span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default LineItemCardRow
